import styled from '@emotion/styled'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { OffersList } from 'app/components/OffersList'
import { SEO } from 'app/components/SEO'
import { VisualBenefits } from 'app/components/VisualBenefits'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id?: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function OffersPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero variant="compact" {...context.heroProps} />
      ) : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.offersListProps ? (
        <OffersList {...context.offersListProps} />
      ) : null}
      {context.visualBenefitsProps ? (
        <VisualBenefits separator={false} {...context.visualBenefitsProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
