import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  category?: string
  className?: string
  image?: ImageProps
  languageCode: string
  pricefrom?: string
  title: string
  URL?: string
  validity?: string
  weight?: number
}

export const Offer = memo(function Offer({
  category,
  className,
  image,
  languageCode,
  pricefrom,
  title,
  URL,
  validity,
}: Props) {
  return (
    <CTA className={className} data-category={category} to={URL ? URL : ''}>
      <Background className="offer-bg">
        <LazyLoad>
          <Image style={{ backgroundImage: `url(${image?.src})` }} />
        </LazyLoad>
      </Background>
      {validity ? (
        <Validity>{`${useVocabularyData(
          'valid-offer',
          languageCode,
        )} ${validity}`}</Validity>
      ) : null}
      <Wrapper className="offer-wrap">
        {category ? <Category>{category}</Category> : null}
        <Title>{title}</Title>
        <Info dial={4} row space="between">
          <Anchor
            className="offer-anchor"
            label={useVocabularyData('details', languageCode)}
            noActions
            variant="corners"
          />
          {pricefrom ? <Pricefrom>{pricefrom}</Pricefrom> : null}
        </Info>
      </Wrapper>
    </CTA>
  )
})

const CTA = styled(Link)`
  width: calc(50% - 1.25rem);
  margin-top: 5.5625rem;
  padding-top: 23%;
  position: relative;
  display: none;
  &.visible {
    display: block;
  }
  &:hover {
    .offer-bg {
      &:before {
        background: ${({ theme }) => theme.colors.variants.primaryDark1};
        opacity: 0.7;
      }
      span {
        transform: scale(1.1);
      }
    }
    .offer-wrap {
      transform: translateY(-1.875rem);
    }
    .offer-anchor {
      &:before,
      &:after {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 1023px) {
    margin-top: 3.4375rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-top: 48%;
  }
`

const Background = styled.div`
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 12vh;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
`

const Validity = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  letter-spacing: 2px;
  line-height: 1.125rem;
  padding: 0 1.5rem;
  position: absolute;
  top: 2.25rem;
  left: 0;
  z-index: 2;
  text-align: center;
  text-transform: uppercase;
`

const Wrapper = styled.div`
  width: calc(100% - 4.5rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-left: 2.25rem;
  padding: 3rem;
  position: relative;
  z-index: 2;
  transition: 0.3s ease-in-out;

  @media (max-width: 767px) {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
    padding: 2.25rem 1.5rem;
  }
`

const Category = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.div`
  max-width: 70%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;

  @media (max-width: 767px) {
    max-width: none;
  }
`

const Info = styled(FlexBox)`
  margin-top: 2.25rem;

  @media (max-width: 767px) {
    margin-top: 1.125rem;
  }
`

const Anchor = styled(Button)`
  margin-top: 0;
`

const Pricefrom = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.875rem;
`
